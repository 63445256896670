.MuiAccordion-root {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px !important;
  background: var(--table-body-background);
  &.right-side-accordion {
    border: 1px solid var(--ion-color-primary);
    background: rgba(255, 192, 25, 0.01) !important;
    border-radius: 10px 10px 1px 0px;
    color: var(--text-primary);
    .stream-header {
      background: rgba(255, 192, 25, 0.01) !important;
      
    }
    .MuiCollapse-wrapperInner,
    .MuiAccordion-root .MuiAccordionDetails-root {
      background: rgba(255, 192, 25, 0.01) !important;
    }
  }
  &.add-new-market-accordion {
    border: 1px solid var(--filter-border);
    @media screen and (max-width: 720px) {
      border: 0px !important;
      background: var(--ion-background-secondary) !important;
      border-radius: 40px !important;
    }
    border-radius: 8px !important;
    background: var(--card) !important;
    .MuiAccordionSummary-root {
      border-bottom: 1px solid var(--filter-border);
    }
  }

  .MuiAccordionSummary-root {
    font-style: normal;
    box-sizing: border-box;
    background: var(--side-menu) !important;
    color: var(--text-primary) !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border-radius: 8px;
    text-transform: capitalize;
    align-items: center;
    padding: 0px;
    margin-top: 0px;
    height: 40px !important;
    min-height: 40px !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    .icon-ctn {
      background-color: var(--common-dark);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 30px;
      border-radius: 8px;
      margin-right: 5px;

      .sport-inplay-icon {
        height: 17px;
        width: 17px;
        margin-right: 8px;
      }
    }
    .MuiIconButton-root {
      width: 14px;
      padding: 0px !important;
      margin-right: 10px !important;
    }
    .MuiSvgIcon-root,
    svg {
      fill: var(--teble-head);
    }
    &.Mui-expanded {
      color: var(--text-primary);
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      svg {
        fill: var(--teble-head) !important;
      }
    }
  }
  .MuiCollapse-wrapperInner,
  .MuiAccordionDetails-root {
    display: initial;
    padding: 0;
    background: var(--accordion-details-root-background) !important;
  }
}

.MuiDialog-paper {
  .MuiAccordionDetails-root {
    font-size: 14px;
    padding-left: 10px;
    background: var(--ion-background);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    &.stream-body {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 720px) {
  .MuiAccordion-root {
    background: var(--side-menu) !important;
    .MuiAccordionSummary-root {
      padding: 0;
      font-size: 13px;
      line-height: 17px;
      height: 38px !important;
      min-height: 38px !important;
    }
    .MuiAccordionDetails-root {
      padding: 0;
    }
  }
}

.MuiAccordionSummary-content {
  font-size: 13px;
  font-family: var(--font-family);
}
.MuiAccordion-root .MuiCollapse-wrapperInner,
.MuiAccordion-root .MuiAccordionDetails-root {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.MuiAccordionSummary-content {
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}