:root {
  --ion-color-primary: #ffc019;
  --ion-text-white: #fff;
  --text-common-dark: #23262f;
  --back-odd-background: #59ceff;
  --lay-odd-background: #ff8a71;
  --premium-odd-background: #72e3a0;
  --font-family: 'Poppins';
  --ion-color-success: #008140;
  --ion-color-danger: #f70000;
  --ion-toast--success: #69bb7b;
  --odd-btn-animation-back-color: #c9e7ff;
  --odd-btn-animation-lay-color: #d9afb8;
  --login-card: url('../assets/images/common/logincard.png');
  --dot-bg: #a3a3a3;

  /* Header logo */
  --header-logo-width: 75px;
  --header-logo-height: 55px;

  /** Header Logo Sizes **/
  --logo-xl-width: 75px;
  --logo-xl-height: 47px;
  --logo-lg-width: 218px;

  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /* Theme colors */
  --th1-primary: #ffc019;
  --th1-bg: #303031;

  --th2-primary: #ffc019;
  --th2-bg: #f6f6f4;

  --th3-primary: #64d0b7;
  --th3-bg: #232035;

  --th4-primary: #7033f5;
  --th4-bg: #181429;

  --th5-primary: #53ade4;
  --th5-bg: #303031;

  --th6-primary: #db3732;
  --th6-bg: #202020;

  --th7-primary: #c73e8d;
  --th7-bg: #1a2538;

  --th8-primary: #c73e8d;
  --th8-bg: #303031;

  --th9-primary: #18bb4e;
  --th9-bg: #131416;

  --th10-primary: #a66a0e;
  --th10-bg: #131416;
}

.ios body,
.md body {
  --search-background:rgb(42, 41, 41); 
  --ion-color-primary: #ffc019;
  --login-input-bg: #303031;
  --ion-header-background: #19191b;
  --ion-background: #19191b;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: #303030;
  --ion-background-tertiary: #303032;
  --common-dark: #000;
  --text-1: rgba(0, 0, 0, 0.8);
  --text-primary: #fff;
  --text1: rgba(255, 255, 255, 0.6);
  --text2: rgba(0, 0, 0, 0.6);
  --text3: #000;
  --teble-head: rgba(255, 255, 255, 0.4);
  --table-head-bg: linear-gradient(180deg, #444444 0%, #111111 100%);
  --table-body-background: linear-gradient(
    180deg,
    #474747 -50%,
    #121111 130.04%
  );
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: rgba(255, 255, 255, 0.1);
  --side-menu: #262525;
  --footer-bg: #000;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  /* Scorecard */
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.2);
  --modal-bg: #231f20;
}

.ios body.dark,
.md body.dark {
  --search-background:rgb(154, 154, 154); 
  --ion-text-white: rgb(43, 35, 35);
  --ion-text-white: #000;
  --ion-color-primary: #ffc019;
  --ion-header-background: #f6f6f4;
  --dot-bg: #18bb4e;
  --ion-background: #f5f5f5;
  --ion-background-tertiary: #dededc;
  --ion-background-color: #ffffff;
  --ion-background-secondary: rgba(0, 0, 0, 0.3);
  --common-dark: #fff;
  --text-1: #fff;
  --text-primary: #000;
  --text1: #000;
  --text2: rgba(0, 0, 0, 0.4);
  --text3: #000;
  --teble-head: #000;
  --table-head-bg: #dededc !important;
  --table-body-background: #f6f6f4;
  --disabled-bg: rgba(0, 0, 0, 0.4);
  --card: #fff;
  --side-menu: #e5e5e5;
  --footer-bg: #fff;
  --mob-footer-bg: #dededc;
  --scorecard-text: #000;
  --scorecard-bg: #fff;
  --filter-border: #c5c5c3;
  --filter-border1: #c5c5c3;
  --accordion-details-root-background: #f6f6f4;
  --input-text-color: #000;
  --login-input-bg: #fff;
  --input-bg: rgba(0, 0, 0, 0.1);
  --suspended-bg: rgba(35, 31, 32, 0.9);
  --suspended-text: #fff;
  --font-weight: 400;
  --modal-bg: rgba(0, 0, 0, 0.1);
  --border: solid rgba(0, 0, 0, 0.1);
  --date-icon: invert(1);
  --game-category-icons: brightness(0) invert(1);
  --game-category-icons1: invert(1);
  --login-card: url('../assets/images/common/loginlight.png');
}

.ios body.green,
.md body.green {
  --ion-color-primary: #64d0b7;
  --login-input-bg: #303031;
  --ion-header-background: #232035;
  --ion-background: #181429;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: #231f35;
  --ion-background-tertiary: #231f35;
  --common-dark: #0b071f;
  --text-1: rgba(0, 0, 0, 0.8);
  --text-primary: #fff;
  --text1: #fff;
  --text2: rgba(0, 0, 0, 0.6);
  --text3: #000;
  --teble-head: #fff;
  --table-head-bg: linear-gradient(180deg, #444444 0%, #111111 100%);
  --table-body-background: #181429;
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: rgba(255, 255, 255, 0.1);
  --side-menu: #231f35;
  --footer-bg: #0b071f;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.4);
  --modal-bg: #231f20;
}

.ios body.purple,
.md body.purple {
  --ion-color-primary: #7033f5;
  --login-input-bg: #303031;
  --ion-header-background: #181429;
  --ion-background: #181429;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: rgba(255, 255, 255, 0.1);
  --ion-background-tertiary: rgba(255, 255, 255, 0.12);
  --common-dark: #0b071f;
  --text-1: rgba(0, 0, 0, 0.8);
  --text-primary: #fff;
  --text1: #fff;
  --text2: #fff;
  --text3: #fff;
  --teble-head: #fff;
  --table-head-bg: linear-gradient(180deg, #444444 0%, #111111 100%);
  --table-body-background: #181429;
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: rgba(255, 255, 255, 0.1);
  --side-menu: #231f35;
  --footer-bg: #0b071f;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.4);
  --modal-bg: #231f20;
}

.ios body.blue,
.md body.blue {
  --ion-color-primary: linear-gradient(90deg, #2979cd 0%, #4dbaec 100%);
  --ion-color-primary: #2979cd;
  --login-input-bg: #303031;
  --ion-header-background: #303032;
  --ion-background: #202020;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: rgba(255, 255, 255, 0.1);
  --ion-background-tertiary: rgba(255, 255, 255, 0.1);
  --common-dark: #19191b;
  --text-1: #fff;
  --text-primary: #fff;
  --text1: rgba(255, 255, 255, 0.6);
  --text2: #fff;
  --text3: #fff;
  --teble-head: rgba(255, 255, 255, 0.6);
  --table-head-bg: #202020;
  --table-body-background: #202020;
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: rgba(255, 255, 255, 0.1);
  --side-menu: #262525;
  --footer-bg: #19191b;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.4);
  --modal-bg: #231f20;
}

.ios body.orange,
.md body.orange {
  --ion-color-primary: #db3732;
  --login-input-bg: #303031;
  --ion-header-background: #19191b;
  --ion-background: #202020;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: #303030;
  --ion-background-tertiary: #303032;
  --common-dark: #000;
  --text-1: #fff;
  --text-primary: #fff;
  --text1: rgba(255, 255, 255, 0.8);
  --text2: #fff;
  --text3: #fff;
  --teble-head: rgba(255, 255, 255, 0.4);
  --table-head-bg: linear-gradient(180deg, #444444 0%, #111111 100%);
  --table-body-background: linear-gradient(
    180deg,
    #474747 -50%,
    #121111 130.04%
  );
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: rgba(255, 255, 255, 0.1);
  --side-menu: #262525;
  --footer-bg: #000;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.2);
  --modal-bg: #231f20;
}

.ios body.pink,
.md body.pink {
  --ion-color-primary: #c73e8d;
  --login-input-bg: #010d22;
  --ion-header-background: #1a2538;
  --ion-background: #121b30;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: rgba(255, 255, 255, 0.12);
  --ion-background-tertiary: rgba(255, 255, 255, 0.1);
  --common-dark: #010d22;
  --text-1: #fff;
  --text-primary: #fff;
  --text1: rgba(255, 255, 255, 0.1);
  --text2: #fff;
  --text3: #fff;
  --teble-head: rgba(255, 255, 255, 0.6);
  --table-head-bg: #121b30;
  --table-body-background: #121b30;
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: #010d22;
  --side-menu: #1a2538;
  --footer-bg: #010d22;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.2);
  --modal-bg: #010d22;
}

.ios body.pink1,
.md body.pink1 {
  --ion-color-primary: #c73e8d;
  --login-input-bg: #303031;
  --ion-header-background: #19191b;
  --ion-background: #202020;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: #303030;
  --ion-background-tertiary: #303032;
  --common-dark: #000;
  --text-1: #fff;
  --text-primary: #fff;
  --text1: rgba(255, 255, 255, 0.8);
  --text2: #fff;
  --text3: #fff;
  --teble-head: rgba(255, 255, 255, 0.4);
  --table-head-bg: linear-gradient(180deg, #444444 0%, #111111 100%);
  --table-body-background: linear-gradient(
    180deg,
    #474747 -50%,
    #121111 130.04%
  );
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: rgba(255, 255, 255, 0.1);
  --side-menu: #262525;
  --footer-bg: #000;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.2);
  --modal-bg: #231f20;
}

.ios body.green1,
.md body.green1 {
  --ion-color-primary: #18bb4e;
  --login-input-bg: #303031;
  --ion-header-background: #19191b;
  --ion-background: #131416;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: #19191b;
  --ion-background-tertiary: #19191b;
  --common-dark: #000;
  --text-1: #fff;
  --text-primary: #fff;
  --text1: rgba(255, 255, 255, 0.8);
  --text2: #fff;
  --text3: #fff;
  --teble-head: rgba(255, 255, 255, 0.6);
  --table-head-bg: #19191b;
  --table-body-background: #19191b;
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: #19191b;
  --side-menu: #19191b;
  --footer-bg: #000;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.2);
  --modal-bg: #19191b;
}

.ios body.brown,
.md body.brown {
  --ion-color-primary: #a66a0e;
  --login-input-bg: #020535;
  --ion-header-background: #172329;
  --ion-background: #020535;
  --ion-background-color: var(--ion-background);
  --ion-background-secondary: #1b2a6d;
  --ion-background-tertiary: #1b2a6d;
  --common-dark: #0c144a;
  --text-1: rgba(0, 0, 0, 0.8);
  --text-primary: #fff;
  --text1: #fff;
  --text2: rgba(0, 0, 0, 0.6);
  --text3: #000;
  --teble-head: #fff;
  --table-head-bg: #16235d;
  --table-body-background: #16235d;
  --disabled-bg: rgba(255, 255, 255, 0.1);
  --card: #192665;
  --side-menu: #1b2a6d;
  --footer-bg: #172329;
  --mob-footer-bg: #212123;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --input-text-color: #ffffff;
  --input-bg: var(--ion-background);
  --icon: #8f8f8f;
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --filter-border: rgba(255, 255, 255, 0.4);
  --modal-bg: #020535;
}
